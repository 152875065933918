import { render, staticRenderFns } from "./VisitRecords.vue?vue&type=template&id=663310fd&scoped=true&"
import script from "./VisitRecords.vue?vue&type=script&lang=js&"
export * from "./VisitRecords.vue?vue&type=script&lang=js&"
import style0 from "./VisitRecords.vue?vue&type=style&index=0&id=663310fd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "663310fd",
  null
  
)

export default component.exports