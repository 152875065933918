<template>
  <div class="List">
    <van-nav-bar title="历史记录" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" class="list-box">
        <CaseRecord v-for="(item, index) in list" :item="item" :type="type" :key="index" class="item">
        </CaseRecord>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Toast } from "vant";
import Api from "@/api/caseHandle";
import ApiDetail from "@/api/caseDetail";

export default {
  props: {},
  components: {},
  data() {
    return {
      homeNo: this.$route.query.homeNo || "",
      assignNo: this.$route.query.assignNo || "",
      type: this.$route.query.type || "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageNo: 1,
      pageSize: 10,
    };
  },
  computed: {},
  methods: {
    async onLoad() {
      try {
        this.finished = false;
        this.loading = true;
        const { type, pageNo, pageSize } = this;
        const params = {
          pageNo,
          pageSize,
          homeNo: this.homeNo,
          assignNo: this.assignNo,
        };

        let pageFun = ApiDetail.familyPage;
        switch (type) {
          case 'family':
            pageFun = ApiDetail.familyPage
            break;
          case 'visit':
            pageFun = ApiDetail.visitPage
            break;
          case 'repayment':
            pageFun = ApiDetail.repaymentPage
            break;
          case 'feedback':
            pageFun = Api.feedbackPage
            break;
          default:
            break;
        }

        let res = await pageFun(params);
        const { data } = res;
        const { list } = data;
        if (this.pageNo == 1) {
          this.list = list;
        } else {
          this.list = [...this.list, ...list];
        }
        this.finished = !data.isNextPage;
        this.pageNo++;
      } catch (error) {
        Toast.fail(error);
        this.list = [];
        this.finished = true;
      } finally {
        this.loading = false;
        this.refreshing = false;
      }
    },
    onRefresh() {
      this.pageNo = 1;
      this.onLoad();
    },
  },
  mounted() {

  },
};
</script>

<style lang="less" scoped>
.List {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.list-box {
  padding: 10px 12px;

  .item {
    margin-bottom: 15px;
  }
}
</style>
